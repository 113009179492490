import { useState } from "react";
import { observer } from "mobx-react-lite";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay, Controller, Pagination } from "swiper/modules";
import he from "he";

import { useGetAll, useSwiperRef, useWindowSize } from "shared/lib";

import s from "./styles.module.sass";
import "swiper/css";
import "swiper/css/navigation";

import { ArrowLeftIcon, ArrowRightIcon, Button } from "shared/ui";
import { useCarouselStore } from "entities/Carousel";
import { placeApi } from "shared/api";
import { API_URL } from "shared/config";

export const AfishaSub = observer(() => {
  const [swiperMain, setSwiperMain] = useState(null);
  const [swiperText, setSwiperText] = useState(null);
  const [pagination, paginationRef] = useSwiperRef();
  const [width] = useWindowSize();

  const carousel = useCarouselStore();
  const activeClass = carousel.activeIndex >= 0 ? s.active : "";

  const afishaSubClass = `${s.section} ${activeClass}`;
  const { resData } = useGetAll();
  console.log(resData);

  return (
    <section id="afisha" className={afishaSubClass}>
      <div className={s.left}>
        <div className={s.top}></div>
        <div className={s.bottom}>
          <div className={s.text}>
            <span>Афиша</span>
          </div>
          <div className={s.navigation}>
            <Button
              type="rounded"
              variable="primaryInvarion"
              onClick={() => {
                //@ts-ignore
                swiperMain.slidePrev();
              }}
            >
              <ArrowLeftIcon />
            </Button>
            <Button
              type="rounded"
              variable="primaryInvarion"
              onClick={() => {
                //@ts-ignore
                swiperMain.slideNext();
              }}
            >
              <ArrowRightIcon />
            </Button>
          </div>
        </div>
      </div>
      <div className={s.center}>
        <div className={s.mainSwiperWrapper}>
          <div className={s.swiperWrapper}>
            {resData ? (
              <Swiper
                //@ts-ignore
                onSwiper={(swiper) => setSwiperMain(swiper)}
                speed={600}
                loop
                allowTouchMove={false}
                grabCursor={false}
                direction={"vertical"}
                modules={[Navigation, Autoplay, Controller, Pagination]}
                className={s.swiper}
                autoplay={{
                  delay: 4000,
                  disableOnInteraction: false,
                }}
                controller={{ control: swiperText }}
                normalizeSlideIndex
                pagination={{
                  //@ts-ignore
                  el: pagination,
                  type: "bullets",
                  bulletClass: "pagination-bullet",
                  bulletActiveClass: "pagination-bullet-active",
                  clickable: true,
                }}
                // onSlideChange={(swiper) => {
                //   //@ts-ignore
                //   swiperText.slideTo(swiper.realIndex);
                // }}
              >
                {resData["Афиши"].map((afisha, index) => (
                  <SwiperSlide key={index} className={s.slide}>
                    {width <= 1024 ? (
                      <img
                        src={`${API_URL}${afisha.MOB_PICTURE}`}
                        alt="afisha"
                      />
                    ) : (
                      <img
                        src={`${API_URL}${afisha.DESC_PICTURE}`}
                        alt="afisha"
                      />
                    )}
                  </SwiperSlide>
                ))}
              </Swiper>
            ) : null}

            <div
              ref={paginationRef}
              className={`pagination ${s.pagination}`}
            ></div>
          </div>
        </div>
      </div>
      <div className={s.right}>
        {resData ? (
          <div className={s.textSwiperWrapper}>
            <Swiper
              //@ts-ignore
              onSwiper={(swiper) => setSwiperText(swiper)}
              speed={600}
              loop
              spaceBetween={40}
              // direction={"vertical"}
              allowTouchMove={false}
              grabCursor={false}
              normalizeSlideIndex
              className={s.swiper}
              modules={[Autoplay, Controller]}
              // autoplay={{
              //   delay: 4000,
              //   disableOnInteraction: false,
              // }}
              breakpoints={{
                0: {
                  spaceBetween: 10,
                  allowTouchMove: false,
                  grabCursor: false,
                  direction: "horizontal",
                },
                500: {
                  spaceBetween: 40,
                },
                1024: {
                  direction: "vertical",
                },
              }}
              controller={{ control: swiperMain }}
            >
              {resData["Афиши"].map((afisha, index) => (
                <SwiperSlide key={index} className={s.slide}>
                  <div className={s.top}>
                    <span className={s.name}>{afisha.NAME}</span>
                    <span className={s.time}>
                      {afisha.DATE} {afisha.MONTH}, {afisha.TIME}
                    </span>
                  </div>
                  <div className={s.center}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: he.decode(afisha.DESCR),
                      }}
                    >
                      {}
                    </div>
                    <Button
                      link="https://api.whatsapp.com/send/?phone=79000039044&text&type=phone_number&app_absent=0"
                      variable="surfaceInvarion"
                      className={s.btn}
                    >
                      Забронировать
                    </Button>
                  </div>
                  <div className={s.bottom}>
                    <Button
                      link="https://api.whatsapp.com/send/?phone=79000039044&text&type=phone_number&app_absent=0"
                      variable="surfaceInvarion"
                      className={s.btn}
                    >
                      Забронировать
                    </Button>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        ) : null}

        <div className={s.text}>
          <div className={s.row}>
            <span className={s.label}>Адрес:</span>
            <span className={s.value}>г. Сочи, ул. Войкова, д.3</span>
          </div>
          <div className={s.row}>
            <span className={s.label}>Зарезервировать:</span>
            <span className={s.value}>{resData && resData["Телефон"]}</span>
          </div>
        </div>
      </div>
    </section>
  );
});
